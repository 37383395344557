<template>
  <section class="hero is-info is-fullheight">
    <div class="hero-head">
      <Navbar :logoLight="true" />
    </div>

    <div class="hero-body is-align-items-flex-end">
      <div class="container">
        <h1 class="subtitle is-size-1">
          Corporate merch<br />reinvented
        </h1>
      </div>
    </div>
  </section>

  <section class="hero is-light">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div data-aos="fade-right" class="column
          is-12-mobile 
          is-6-tablet
          is-5-desktop">
            <h3 class="subtitle is-size-3">
              The easiest way to design, create and send custom products to your customers, securely and privately.
            </h3>
            <p>Provide clients with branded gifts worldwide without needing their address, anytime you choose.</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="hero is-light">
    <div class="hero-body">
      <div class="container">

        <h3 class="subtitle is-size-3 has-text-centered">
          Our catalog
        </h3>

        <div class="columns is-centered is-vcentered is-multiline is-mobile">
          <div data-aos="fade-right" class="column
          is-6-mobile 
          is-3-tablet
          is-3-desktop">
            <div class="product has-text-centered">
              <figure class="image mb-3">
                <img src="https://placehold.co/300x320/png" />
              </figure>
              <h4 class="subtitle">Heavyweight t-shirt</h4>
            </div>
          </div>
          <div data-aos="fade-right" class="column
          is-6-mobile 
          is-3-tablet
          is-3-desktop">
            <div class="product has-text-centered">
              <figure class="image mb-3">
                <img src="https://placehold.co/300x320/png" />
              </figure>
              <h4 class="subtitle">Heavyweight t-shirt</h4>
            </div>
          </div>
          <div data-aos="fade-right" class="column
          is-6-mobile 
          is-3-tablet
          is-3-desktop">
            <div class="product has-text-centered">
              <figure class="image mb-3">
                <img src="https://placehold.co/300x320/png" />
              </figure>
              <h4 class="subtitle">Heavyweight t-shirt</h4>
            </div>
          </div>
          <div data-aos="fade-right" class="column
          is-6-mobile 
          is-3-tablet
          is-3-desktop">
            <div class="product has-text-centered">
              <figure class="image mb-3">
                <img src="https://placehold.co/300x320/png" />
              </figure>
              <h4 class="subtitle">Heavyweight t-shirt</h4>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>


  <div class="columns mb-0">
    <div class="column landing2">
      <section class="hero is-fullheight">
        <div class="hero-body is-align-items-flex-end">
          <div>
            <h3 class="subtitle has-text-white is-size-3">
              Create wow-worthy experiences
            </h3>
            <router-link :to="{ name: 'Experience' }" class="button is-light">
              How it works
            </router-link>
          </div>
        </div>
      </section>
    </div>
    <div class="column landing3">
      <section class="hero is-fullheight">
        <div class="hero-body is-align-items-flex-end">
          <div>
            <h3 class="subtitle has-text-white is-size-3">
              Gifting on the go made easy
            </h3>
            <router-link :to="{ name: 'Solutions' }" class="button is-light">
              Our solutions
            </router-link>
          </div>
        </div>
      </section>
    </div>
  </div>

  <Footer />
</template>

<script>
import { onBeforeMount } from 'vue'
import Navbar from '@/components/Navbar1.vue'
import Footer from '@/components/Footer.vue'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default {
  components: { Navbar, Footer },
  setup() {
    onBeforeMount(() => {
      import('aos').then(AOS => AOS.init())
    })
  }
}
</script>


<style scoped>
@media screen and (max-width: 768px) {
  .column > .hero.is-fullheight {
    min-height: 50vh;
  }
}

.hero.is-info {
  background: url('../assets/landing.jpg');
  background-size: cover;
}

.landing2 {
  background: url('../assets/landing2.jpg');
  background-size: cover;
}

.landing3 {
  background: url('../assets/landing3.jpg');
  background-size: cover;
}
</style>